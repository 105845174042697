import { Link } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import Newsletter from "../../../src/assets/images/newsletterImg.png"
import "../../styles/component/popup-signup.scss"
import Buttons from "./button"
import FormBuilder from "./FormBuilder/Form-Builder"
import SVGIcon from "./SVGIcon"

function PopUpForm(props) {
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const handleAfterSubmit = (res, items) => {
    setSubmitSuccess(true)
    if (props?.afterSumbitRedirectUrl)
      window.location.href = `/${props.afterSumbitRedirectUrl}`
    if (props.embedTarget) {
      const formData = items.reduce((memo, curr) => {
        memo[curr.name] = curr.value
        return memo
      }, {})
      if (res?.status == 200) {
        window.postMessage({ type: "rhFormSubmit", formData })
      }
    }
  }

  return (
    <div className={`popup-signup ${props.visibility ? "" : "dspnone"}`}>
      <div
        className="wrap position-fixed"
        onClick={() => {
          props.togglePopup(false)
          setSubmitSuccess(false)
        }}
        onKeyDown={() => {
          props.togglePopup(false)
          setSubmitSuccess(false)
        }}
        role="button"
        tabIndex={0}
      ></div>

      <Container className="position-fixed form-container">
        <div className="overflow">
          <div
            className="close position-absolute"
            onClick={() => {
              props.togglePopup(false)
              setSubmitSuccess(false)
            }}
            onKeyDown={() => {
              props.togglePopup(false)
              setSubmitSuccess(false)
            }}
            role="button"
            tabIndex={0}
          >
            {props.isCloseLight ? (
              <SVGIcon name={"close-light"} />
            ) : (
              <img
                src="https://media.graphcms.com/zpyoXoSXRuWnSQ1KauLF"
                alt="close"
                height="24"
                width="24"
              />
            )}
          </div>

          <div className="forms">
            {submitSuccess && !props.path ? (
              props.successData ? (
                props.successData
              ) : (
                <div className="submit-success d-flex flex-column align-items-center justify-content-center text-grey">
                  {props.halloween ? (
                    <div>
                      <img
                        src="https://media.graphassets.com/VhRi5hQBTbeNbrGJo72w"
                        className="w-100 pumpkin"
                      />
                    </div>
                  ) : !props.hideImage ? (
                    <img src={Newsletter} alt="newsletter img" />
                  ) : null}
                  {props.embedTarget ? (
                    <div
                      id={props.embedTarget}
                      className="w-100"
                      style={{
                        minHeight: "440px",
                      }}
                    />
                  ) : null}
                  <p className="p24 fw-bold font-roboto mb16 mt24 suc-msg-1">
                    {props.successMsg}
                  </p>
                  {props.successMsg2 && (
                    <p className="mb40 mt10 suc-msg-2">{props.successMsg2}</p>
                  )}
                  {props.successBtnText && (
                    <Buttons
                      onClick={() => setSubmitSuccess(false)}
                      theme={"primary"}
                      text={props.successBtnText}
                    />
                  )}
                </div>
              )
            ) : (
              <>
                {props.formTitle && (
                  <span
                    className={`p16 info ${
                      props.halloween ? "" : "text-center"
                    }`}
                  >
                    <p className="info">{props.formTitle}</p>
                  </span>
                )}

                <FormBuilder
                  formClassName="forms"
                  errorClassName="error-message"
                  inputErrorClassName="error-active"
                  data={props.formData}
                  buttonText={
                    props.formButtonText ? props.formButtonText : "SUBMIT"
                  }
                  buttonClassName="primary"
                  endpoint={props.endpoint}
                  url={props.url}
                  formName={props.formName}
                  successMsg={true}
                  afterSubmit={(res, items) => handleAfterSubmit(res, items)}
                  path={props.path}
                  {...props.additionalFormProps}
                  extradata={props.extradata}
                  handleGetSuccessData={props.handleGetSuccessData}
                />

                <span className="action p12 a12">
                  {props.bottomMessage ? (
                    <center>
                      <p>{props.bottomMessage}</p>
                    </center>
                  ) : (
                    <center>
                      <p>
                        By clicking "Submit", you agree to SuperOps'{" "}
                        <Link to="/terms">Terms of use</Link> and{" "}
                        <Link to="/privacy">Privacy policy</Link>.
                      </p>
                    </center>
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PopUpForm
