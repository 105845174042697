import { gql, useLazyQuery } from "@apollo/client"
import { graphql, Link } from "gatsby"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Fade from "react-reveal/Fade"
import Favicon from "../assets/images/favicon.png"
import Logo from "../assets/svgs/spsLogoDarkNew.svg"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import PopUpForm from "../components/common/PopUpForm"
import SVGIcon from "../components/common/SVGIcon"
import "../styles/pages/christmas-2025.scss"

const formdata = [
  {
    name: "referral_email",
    type: "email",
    label: "From email",
    value: "",
    errorMessage: "Please enter a valid email",
  },
  {
    name: "email",
    type: "email",
    label: "To email",
    value: "",
    errorMessage: "Please enter a valid email",
  },

  {
    name: "message",
    type: "textarea",
    validateEmpty: "true",
    label: "Your message",
    value: "",
    errorMessage: "Please enter a valid message",
  },
]

const iconsList = ["x-icon", "insta-icon", "ln-icon", "fb-icon"]

const ChristmasWishJar = ({ data }) => {
  const {
    heroSection,
    pageImages,
    newField,
    ctaBoxes,
    seo,
  } = data.SuperOps.pages[0]
  const { links } = data.SuperOps
  const messageCard = newField[0]
  const formInfo = newField[1]
  const videoRef = useRef(null)

  const [showHero, setShowHero] = useState(false)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [heroVideo, setHeroVideo] = useState("")
  const [heroAnimation, setHeroAnimation] = useState("")
  const [windowSize, setWindowSize] = useState(0)

  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  const GET_LIVE_DATA = gql`
    query christmasLiveData {
      liveDatas(where: { type: christmas2024 }, orderBy: createdAt_DESC) {
        from
        to
        content
      }
    }
  `
  const [messageData, setMessageData] = useState([])

  const [GetLiveData, { loading, error, liveData }] = useLazyQuery(
    GET_LIVE_DATA,
    {
      fetchPolicy: "network-only",
      onCompleted: res => {
        setMessageData(res?.liveDatas)
      },
    }
  )

  const handleGetSuccessData = data => {
    const obj = {
      from: data[0]?.value.split("@")[0],
      to: data[1]?.value.split("@")[0],
      content: data[2]?.value,
    }

    const updatedData = [obj, ...messageData]
    setMessageData(updatedData)
  }

  useEffect(() => {
    setWindowSize(window.innerWidth)
    GetLiveData()
  }, [])

  useEffect(() => {
    if (window.innerWidth < 769) {
      setShowHero(true)
    }
  }, [])

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      if (videoRef.current.readyState === 4) {
        setHeroVideo("hero-video")
        setTimeout(() => {
          setShowHero(true)
          setHeroAnimation("heading-block")
        }, 1000)
      }
    } else {
      setShowHero(true)
      setHeroVideo("hero-video")
      setHeroAnimation("heading-block")
    }
  }, [videoRef?.current?.readyState])

  const handleCopyText = useCallback(data => {
    navigator.clipboard.writeText(data.previousElementSibling.innerText)
  }, [])

  let delayCount = 0

  const one = [],
    two = [],
    three = []
  let checker = 1

  messageData &&
    messageData.map(item => {
      if (checker === 1) {
        one.push(item)
      } else if (checker === 2) {
        two.push(item)
      } else {
        three.push(item)
      }
      checker += 1
      if (checker === 4) {
        checker = 1
      }
    })

  return (
    <div className="christmas-page w-100 ">
      <Helmet>
        <title>{seo?.title}</title>
        <meta property="og:image" content={seo?.image?.url} />
        <meta name="description" content={seo?.description} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon} />
      </Helmet>
      <div className="hero-wrapper">
        {showHero && (
          <section className="hero-content-wrapper d-flex d-dsk">
            <Col lg={6}></Col>
            <Col lg={6} className={`right-hero ${heroAnimation}`}>
              <div className="nav-section">
                <p className="nav-head down-to-up-1">
                  {heroSection.heroExcerpt}
                </p>
                <Link to="/" className="logo down-to-up-1">
                  <Logo className="objcvr" />
                </Link>
              </div>

              <div className={`heading `}>
                <div className="hero-heading-wrapper">
                  <img
                    src={heroSection.images[0].url}
                    className="twinkle down-to-up-2"
                    alt="Twinkle-img"
                  />
                  <p className="hero-heading down-to-up-2">
                    {heroSection.heading.text}
                  </p>
                </div>
                <p className="hero-desc down-to-up-3">
                  {heroSection.content.text}
                </p>
              </div>
            </Col>
          </section>
        )}

        <section className="hero d-dsk">
          <video
            autoPlay
            loop
            muted
            preload="auto"
            src={heroSection.heroImage.url}
            className={heroVideo}
            ref={videoRef}
          />
        </section>
      </div>

      <div className="hero-wrapper d-mob min-h-100">
        <section className="hero-content-wrapper d-flex">
          <Col lg={6}></Col>
          <Col lg={6} className={`right-hero ${heroAnimation}`}>
            <div className="nav-section">
              <p className="nav-head down-to-up-1">{heroSection.heroExcerpt}</p>
              <Link to="/" className="logo down-to-up-1">
                <Logo className="objcvr" />
              </Link>
            </div>

            <div className={`heading `}>
              <div className="hero-heading-wrapper">
                <img
                  src={heroSection.images[0].url}
                  className="twinkle down-to-up-2"
                  alt="Twinkle-img"
                />
                <p className="hero-heading down-to-up-2">
                  {heroSection.heading.text}
                </p>
              </div>
              <p className="hero-desc down-to-up-3">
                {heroSection.content.text}
              </p>
            </div>
          </Col>
        </section>

        <section className="hero">
          <video
            autoPlay
            loop
            muted
            preload="auto"
            src={heroSection.heroImage.url}
            className={`${heroVideo} down-to-up-4`}
            ref={videoRef}
            style={{ width: `${windowSize}px` }}
          />
        </section>
      </div>
      <section className="messages-container Layout-container down-to-up-5">
        <div className="wrapper">
          <Fade bottom duration={1000}>
            <p className="m-title">{messageCard.content[0].text}</p>
          </Fade>
          <Fade bottom duration={1000}>
            <Buttons
              theme={"model-btn btn-primary-new btn down-to-up-5"}
              text={messageCard.text[0]}
              onClick={() => togglePopup()}
            />
          </Fade>

          <div className="cards-container d-flex row flex-wrap">
            {[one, two, three].map((item, id) => {
              return (
                <Col lg={4}>
                  {item.map(data => {
                    if (delayCount > 5) {
                      delayCount = 0
                    }

                    return (
                      <Fade
                        bottom
                        delay={(delayCount + 1) * 130}
                        duration="800"
                        distance={"20px"}
                      >
                        <span className="dspnone">{delayCount++}</span>
                        <Col lg={12}>
                          <div className="card">
                            <img
                              src={messageCard.image[0].url}
                              alt="green-wheel"
                              className="green-wheel"
                            />
                            <div>
                              <p className="to">TO</p>
                              <p className="to-name">{data.to}</p>

                              <p className="content">{data.content}</p>
                              <p className="from">FROM</p>
                              <p className="from-name">{data.from}</p>
                            </div>
                          </div>
                        </Col>
                      </Fade>
                    )
                  })}
                </Col>
              )
            })}
          </div>
        </div>
      </section>

      <Fade bottom duration={1000}>
        <section className="bottom-gift-box">
          <img
            src={pageImages[1].url}
            alt="gift-box-img"
            className="gift-box-img down-to-up-6"
          />
        </section>
      </Fade>

      <section className="cta-container">
        <Fade bottom duration={1000}>
          <CTA
            data={ctaBoxes}
            openPopUp={() => togglePopup()}
            disallowCommonDomains
            newButton
          />
        </Fade>
      </section>

      <div className="form-container">
        <PopUpForm
          visibility={popupVisibility}
          togglePopup={val => {
            setPopupVisibility(val)
          }}
          formData={formdata}
          isCloseLight
          endpoint={process.env.HUBSPOT_CHRISTMAS_2024_ENDPOINT}
          url={process.env.HUBSPOT_CHRISTMAS_24_URL}
          formName="Christmas 2024 Page - Superops"
          formTitle={formInfo.heading}
          formButtonText={formInfo.text[0]}
          successData={
            <SuccessMsg
              data={formInfo}
              handleCopyText={handleCopyText}
              links={links}
            />
          }
          bottomMessage={formInfo.subtext[0]}
          handleGetSuccessData={handleGetSuccessData}
        />
      </div>

      <Fade bottom duration={1000}>
        <Link to="/" className="footer-image down-to-up-8">
          <img src={pageImages[0].url} alt="footer-image" />
        </Link>
      </Fade>
    </div>
  )
}

export default ChristmasWishJar

const SuccessMsg = ({ data, handleCopyText, links }) => {
  const copyRef = useRef(null)

  return (
    <div className="submit-success d-flex flex-column align-items-center justify-content-center text-grey">
      <img
        className="success-gift-box"
        src={data.image[0].url}
        alt="success-gift-box"
      />
      <p className="success-title">{data.text[1]}</p>
      <p className="success-subtext">{data.subtext[1]}</p>
      <div className="success-link">
        <p className="text-link">{data.links[0]}</p>
        <img
          className="copy-img"
          src={data.image[1].url}
          onClick={() => handleCopyText(copyRef.current)}
          ref={copyRef}
        />
      </div>

      <div className="social-icons-container">
        {iconsList.map((icon, idx) => {
          return (
            <Link to={links[0].slug[idx]}>
              <SVGIcon name={icon} />
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    SuperOps {
      pages(where: { title: "Christmas-2025" }) {
        title
        heroSection {
          heading {
            html
            text
          }
          heroImage {
            url
          }
          content {
            html
            text
          }
          images {
            url
          }
          heroExcerpt
        }

        pageImages {
          url
        }

        ctaBoxes {
          type
          theme
          customBackgroundColor
          heading {
            html
          }
          primaryButtonText
          primaryButtonLink
          card {
            image {
              url
            }
          }
        }

        seo {
          title
          description
          keywords
          image {
            url
          }
        }

        newField {
          ... on SuperOps_Card {
            cardName
            cardDescription
            heading
            text
            subtext
            content {
              html
              text
            }
            image {
              url
            }
            links
          }
        }
      }

      links(where: { section: "Social", where: "Footer-V2" }) {
        slug
      }
    }
  }
`
